import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/Seo"
import {
  PrimaryHero,
  HeroCopyBlock,
} from "../components/Heros"
import Button from "../components/Button"
import TriImageBlock from "../components/TriImageBlock"
import ImageBlock from "../components/ImageBlock"
import Spacer from "../components/Spacer"
import OurClients from "../components/OurClients"
import OurCarriers from "../components/OurCarriers"

import deliveryOptionsImg from "./index/img/womanonphone.png"
import globeImg from "./index/img/globe.png"
import planeImg from "./index/img/plane.png"
import homeHeroImg from "./index/img/homehero.jpg"

const IndexPage = () => (
  <Layout>
    <Seo title="Sorted | Home" keywords={[`Sorted`]} />

    <PrimaryHero theme="pink" img={homeHeroImg}>
      <HeroCopyBlock
        title={["Creating powerful", "connections."]}
        buttons={[
          <Button theme="purple"><Link to="/">Learn more</Link></Button>,
          <Button theme="whiteoutline"><Link to="/">Request a demo</Link></Button>
        ]}
      >
        <p>Refreshingly agile and data-driven software for carrier management and delivery tracking. Powering checkouts, warehouses and shipping, through partnerships with some of the biggest global carriers and customer-obsessed retailers.</p>
      </HeroCopyBlock>
    </PrimaryHero>

    <Spacer size="large" sides="topbottom">
      <div class="container-thin">
        <h2 class="text-pink text-center">Our Products.</h2>
        <p class="text-center">Sorted’s clever tech, whether used together or separately, connects each stage of the ecommerce parcel journey— from purchase at checkout, to fulfilment in the warehouse, to doorstep delivery.</p>
      </div>
    </Spacer>

    <div className="container">
      <TriImageBlock>
        <ImageBlock
          linkUrl="https://www.kieranvenison.co.uk"
          theme="light"
          img={deliveryOptionsImg}
        >
          <h3>Delivery options.</h3>
          <p>An API solution dynamically displaying carrier services at checkout with SortedHero.</p>
        </ImageBlock>
        <ImageBlock
          linkUrl="https://www.kieranvenison.co.uk"
          theme="pink"
          img={planeImg}
        >
          <h3>Modern world warehouse.</h3>
          <p>Single-integration carier and shipping.</p>
        </ImageBlock>
        <ImageBlock
          linkUrl="https://www.kieranvenison.co.uk"
          theme="dark"
          img={globeImg}
        >
          <h3>Powerful delivery tracking.</h3>
          <p>Proactive control for customer service teams, self-service  convinience for</p>
        </ImageBlock>
      </TriImageBlock>
    </div>

    <div className="container">
      <Spacer size="large" sides="topbottom">
        <h3 className="text-pink text-center">Our clients.</h3>
        <p className="text-center">Here are just some of the brands joining us on the retail delivery revolution.</p>
        <OurClients />
      </Spacer>
    </div>
    <div className="container-large">
      <hr />
    </div>
    <div className="container">
      <Spacer size="large" sides="topbottom">
        <h3 className="text-pink text-center">Our carriers.</h3>
        <p className="text-center">One single integration with Sorted connects you to thousands of services from some of the biggest global carriers.</p>
        <OurCarriers />
      </Spacer>
    </div>
  </Layout>
)

export default IndexPage